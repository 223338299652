@import "../../assets/stylesheets/base.scss";
.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    padding: 3rem 0 2rem 0;
    font-size: 2rem;
    text-align: center;
  }

  .title::before,
  .title::after {
    display: inline-block;
    content: "";
    border-top: 0.1rem solid $color-4;
    width: 5rem;
    margin: -0.5rem 1.5rem;
    transform: translateY(-1rem);
  }

  .content {
    padding: 0 1rem 1rem 1rem;
    line-height: 160%;
  }

  #techList {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(3, minmax(110px, 250px));
    padding-left: 2rem;

    li {
      font-size: 0.9rem;
    }

    li::before {
      content: "\2022";
      color: $color-3;
      font-weight: bolder;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  .imagesContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 3rem;

    .imageWrapper {
      background-color: $color-2;
      height: 10rem;
      width: 10rem;
      border-radius: 10px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;

      img.aboutImage {
        height: 10rem;
        width: 10rem;
        border-radius: 10px;
        mix-blend-mode: multiply;
        box-shadow: 10px 10px 5px #aaaaaa;
      }
    }
    .imageWrapper:hover {
      background-color: $color-1;
    }
  }

  @media screen and (max-height: 727px) {
    height: auto;
    padding-top: 50px;
  }

  @media screen and (max-width: 500px) {
    height: auto;
    padding-top: 85px;
    .imagesContainer {
      display: flex;
      flex-wrap: wrap;
      margin: 0.5rem;
    }
  }

  @media screen and (max-width: 403px) {
    .title::before,
    .title::after {
      width: 2rem;
    }
  }

  @media screen and (max-width: 300px) {
    .title::before,
    .title::after {
      width: 1rem;
    }
  }

  @media screen and (max-width: 362px) {
    #techList {
      grid-template-columns: repeat(2, minmax(110px, 250px));
    }
  }
}
