@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400&display=swap");
$breakpoint-tablet: 768px;
$min-width: 1024px;
$navbar-offset-height: calc(100vh - 80px);

$color-1: #f6f6f6;

$color-2: #ffc25b;
$color-3: #8390fa;
$color-4: #292929;

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  font-family: "Playfair Display", serif;
  background-color: $color-1;
  color: $color-4;
  width: 100%;
}

@media screen and (max-width: 360px) {
  body {
    display: grid;
  }
}

main {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 80px);

  .section-scroll {
    width: inherit;
  }
}

.section {
  height: 100vh;
}

.icons {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;

  a {
    background-color: $color-3;
    border-radius: 50%;
    svg {
      color: $color-1;
    }
    svg:hover,
    svg:active {
      color: $color-2;
    }
  }
}

@media (min-width: $min-width) {
  main {
    display: grid;

    grid-template-columns: 1fr 1fr;

    .section-scroll {
      grid-column: 2;
    }
  }
}

.under-construction {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0 0 150px 20px;
  height: 100%;
  width: 100%;
  background: no-repeat center url("../images/fiddle.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  h3,
  p {
    width: 50%;
  }
}
