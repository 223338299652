@import "../../assets/stylesheets/base.scss";

.headshot {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  display: block;
  height: 100vh;
  width: 100%;
  object-fit: cover;
  z-index: 1;
}

@media screen and (max-width: $breakpoint-tablet) {
  .headshot {
    height: calc(100vh - 80px);
    width: 100%;
  }
}

// @media (min-aspect-ratio: 7/5) {
//   .headshot {
//     display: none;
//   }
// }
