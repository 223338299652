@import "../../assets/stylesheets/base.scss";

.introContainer {
  height: $navbar-offset-height;
  width: 100%;
  border-right: none;

  display: grid;
  grid-template-rows: 3fr 1fr;

  .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // margin: auto;
    text-align: center;

    .smallFont {
      font-size: 1.2rem;
      padding-top: 25px;
      box-shadow: 0 -4px 2px -3px $color-4;
    }
    .mediumFont {
      font-size: 1.5rem;
      font-weight: normal;
    }
    .name {
      display: block;
      font-size: 3.6rem;
    }
    .welcome {
      padding-bottom: 20px;
      max-width: 95%;
    }
  }

  .icons {
    margin-bottom: 100px;

    a {
      padding: 1.1em 0.3em 0 0.3em;
      svg {
        height: 30px;
        width: 30px;
      }
    }
  }

  @media screen and (max-width: 464px) {
    .intro {
      text-align: center;
    }
    @media screen and (min-width: 344px) {
      .location {
        display: block;
      }
    }
  }
}

@media screen and (min-width: $min-width) {
  .introContainer {
    position: fixed;
    grid-column: 1;
    height: $navbar-offset-height;
    width: 50%;
    border-right: 1px solid #292929;

    .icons {
      margin-bottom: 0px;
    }
  }
}
