@import "../../assets/stylesheets/base.scss";

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    padding-top: 5rem;
    padding-bottom: 3rem;
    font-size: 2rem;
    text-align: center;
  }

  .title::before,
  .title::after {
    display: inline-block;
    content: "";
    border-top: 0.1rem solid $color-4;
    width: 5rem;
    margin: -0.5rem 1.5rem;
    transform: translateY(-1rem);
  }

  .experienceContainer {
    display: flex;
    justify-content: center;
    min-height: 25%;
    padding-left: 10px;

    .companiesTabs {
      border-left: solid thin $color-4;

      border-radius: 5px;
      transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition-delay: 0.1s;
      li {
        list-style-type: none;
        cursor: pointer;
        padding: 20px 5px;
        .tabButton {
          border-color: transparent;
          padding: 5px 3px 5px 0;
          background-color: $color-1;
        }
      }

      li.selected {
        border-left: solid $color-3;
        box-shadow: 10px 10px 5px #aaaaaa;
      }
    }

    .experienceContent {
      background-color: white;
      border: solid thin $color-4;
      width: 65%;
      margin-left: 10px;

      padding: 10px;

      .company {
        color: $color-3;
      }

      .company:hover,
      .compny:active {
        color: $color-2;
      }

      p {
        padding-top: 0.25em;
      }

      li {
        padding: 0.25em 0 0.25em 2em;
        text-indent: -1.5em;
      }
    }
  }

  @media screen and (max-width: 582px) {
    .title::before,
    .title::after {
      width: 3rem;
      margin: -0.5rem 0.5rem;
    }
  }

  @media screen and (min-width: $min-width) {
    .title::before,
    .title::after {
      width: 2rem;
      margin: -0.5rem 1rem;
    }
  }

  @media screen and (max-width: 460px) {
    .title {
      white-space: nowrap;
    }
    .title::before,
    .title::after {
      width: 0;
    }
  }

  @media screen and (max-width: 460px) {
    .title {
      white-space: nowrap;
    }
    .title::before,
    .title::after {
      width: 0;
    }
  }

  @media screen and (max-width: 360px) {
    height: auto;
  }
}
