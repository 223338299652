@import "../../assets/stylesheets/base.scss";

.section {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    padding-bottom: 2rem;
    font-size: 2rem;
    text-align: center;
  }

  .title::before,
  .title::after {
    display: inline-block;
    content: "";
    border-top: 0.1rem solid $color-4;
    width: 5rem;
    margin: -0.5rem 1.5rem;
    transform: translateY(-1rem);
  }

  .contactContent {
    padding: 1rem 2rem;
    line-height: 160%;
  }

  .sayHiButton {
    margin-top: 1rem;
    height: 50px;
    width: 70px;
    text-align: center;
    line-height: 50px;
    background-color: $color-3;
    border-radius: 5px;
    color: $color-1;
    border: transparent;
    box-shadow: 5px 5px 5px #aaaaaa;
    &:active,
    &:hover {
      color: $color-2;
    }
  }

  @media screen and (max-width: 582px) {
    .title::before,
    .title::after {
      width: 3rem;
      margin: -0.5rem 0.5rem;
    }
  }

  @media screen and (min-width: $min-width) {
    .title::before,
    .title::after {
      width: 2rem;
      margin: -0.5rem 1rem;
    }
  }

  @media screen and (max-width: 460px) {
    .title {
      white-space: nowrap;
    }
    .title::before,
    .title::after {
      width: 0;
    }
  }
}
