.nav {
  list-style-type: none;
  display: flex;
}

.nav li {
  border-right: 1px solid #292929;
  padding: 0 0.8rem 0 0.5rem;
  color: black;
  cursor: pointer;
}

.nav li:nth-last-of-type(2) {
  border-right: none;
}

.nav li:last-of-type {
  padding: 0 0.5rem 0 0.5rem;
}

a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
}

.link:hover,
.link:focus {
  color: #ffc25b;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.logo {
  margin-top: 10px;
  height: 80px;
  width: 80px;
}

#resume {
  border: solid;
  border-color: #8390fa;
}

.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
  background-color: rgb(247, 202, 117);
}

.header {
  background-color: #f6f6f6;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  top: 0;
  padding-right: 10px;
  height: 80px;
  z-index: 100;
  box-shadow: 0.5px 1px #292929;
}

.burger {
  display: none;
}

@media (max-width: 767px) {
  .burger {
    display: block;
    float: right;
    position: relative;
    padding-top: 5rem;
    z-index: 101;
  }
  .hamburger-closed,
  .logo {
    display: none;
  }

  .hamburger-open {
    background-color: #e2e2e2;
  }

  .header {
    display: block;
    padding-right: 2rem;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  }

  .nav {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: absolute;
  }

  .nav li {
    border-right: none;
    padding: 0.5rem 0 0.5rem 0;
    font-size: 1.5rem;
  }

  .nav a {
    font-size: 1.5rem;
  }
}
