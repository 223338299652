@import "../../assets/stylesheets/base.scss";

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-around;
  height: auto;
  margin: auto;

  .title {
    padding: 8rem 0 3rem 0;
    font-size: 2rem;
    text-align: center;
  }

  .title::before,
  .title::after {
    display: inline-block;
    content: "";
    border-top: 0.1rem solid $color-4;
    width: 5rem;
    margin: -0.5rem 1.5rem;
    transform: translateY(-1rem);
  }

  .projectCard {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 350px;

    .text,
    .icons {
      display: flex;
      flex-direction: column;
    }
    .text {
      justify-content: center;
    }

    .icons {
      justify-content: space-around;
    }

    .projectInfo {
      background-color: white;
      height: 200px;
      width: 98%;
      border-radius: 2px;
      grid-column: 5 / -1;
      grid-row: 1;
      z-index: 2;
      border: solid $color-4;
      border-width: thin;
      display: flex;
      padding: 10px;
    }

    .imageWrapper {
      background-color: $color-2;
      height: 65%;
      width: 80%;
      margin-left: 10px;
      margin-top: 5%;
      border-radius: 10px;
      grid-column: 1 / span 8;
      grid-row: 1;
      z-index: 1;
      box-shadow: 10px 10px 5px #aaaaaa;

      .projectImage {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        mix-blend-mode: multiply;
      }
    }

    .imageWrapper:hover {
      background-color: $color-1;
      z-index: 3;
    }

    .projectTitle {
      font-size: 1.4em;
    }

    .projectTechUsed {
      li {
        font-size: 0.7rem;
        display: inline;
        padding: 0 0.5rem;
      }
      li:first-of-type {
        padding-left: 0;
      }
    }

    .icons {
      padding-left: 5px;
      li {
        a {
          padding: 0.4em 0.2em 0 0.2em;
          background-color: $color-3;
          border-radius: 50%;
          svg {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }

  .projectCard:last-of-type,
  .projectCard:nth-last-of-type(2) {
    margin-top: -50px;
  }

  .projectCard:nth-last-of-type(2) {
    .icons {
      order: -1;
      padding: 0 10px 0 2px;
    }
  }

  .projectCard:nth-of-type(2) {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);

    .projectInfo,
    .projectImage {
      -moz-transform: scale(-1, 1);
      -webkit-transform: scale(-1, 1);
      -o-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
      transform: scale(-1, 1);
    }
  }

  @media screen and (max-height: 727px) {
    height: auto;
    padding-top: 50px;
  }

  @media screen and (min-width: 650px) and (max-width: 1023px) {
    width: 650px;
  }

  @media screen and (min-width: 1300px) {
    width: 650px;
  }

  @media screen and (max-width: 582px) {
    .title::before,
    .title::after {
      width: 3rem;
      margin: -0.5rem 0.5rem;
    }

    .projectCard {
      grid-template-rows: 400px;
      .projectInfo {
        height: 300px;
      }
      .text {
        :first-child {
          margin-bottom: 5px;
        }
        :last-child {
          margin-top: 5px;
        }
      }
    }
  }

  @media screen and (min-width: $min-width) {
    .title::before,
    .title::after {
      width: 2rem;
      margin: -0.5rem 1rem;
    }
  }

  @media screen and (max-width: 460px) {
    .title {
      white-space: nowrap;
    }
    .title::before,
    .title::after {
      width: 0;
    }
  }
}
