@import "../../assets/stylesheets/base.scss";

.container {
    height: 100vh;
    background-color: $color-3;
    text-align: center;
    .heading {
        padding-top: 100px;
    }

    .saying {
        padding: 7px 27px 27px 27px;
    }

    .paragraph {
        padding: 7px 27px;
    }

    .spacing {
        margin: 100px;
    }

    .presentImage {
        width: 100vw;
        
    }
}